<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-file-input
                            show-size
                            accept="image/png, image/jpeg"
                            :placeholder="trans('fields.offerLogo')"
                            @change="logoChange"
                            :loading="logoLoading"
                        ></v-file-input>
                        <v-img
                            v-if="editedItem && editedItem.logoUrl"
                            contain
                            max-width="100%"
                            :src="editedItem.logoUrl"
                            v-model="logoFile"
                        ></v-img>
                        <v-text-field
                            v-model="editedItem.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-select
                            :items="options.languages"
                            :label="trans('fields.common.language')"
                            v-model="editedItem.language_id"
                            :error-messages="errors.language_id"
                            @input="clearError('language_id')"
                        ></v-select>
                        <v-select
                            :items="options.countries"
                            :label="trans('fields.common.countries')"
                            :multiple="true"
                            v-model="editedItem.countriesArr"
                            :error-messages="errors.countriesArr"
                            @input="clearError('countriesArr')"
                        ></v-select>
                        <v-select
                            :items="options.offerTypes"
                            :label="trans('fields.common.offerType')"
                            v-model="editedItem.type_id"
                            @change="setDefaultFields"
                            :error-messages="errors.type_id"
                            @input="clearError('type_id')"
                        ></v-select>
                        <v-select
                            :items="options.offerScopes"
                            :label="trans('fields.common.scope')"
                            v-model="editedItem.scope"
                            :error-messages="errors.scope"
                            @input="clearError('scope')"
                        ></v-select>
                        <user-autocomplete
                            v-if="editedItem.scope === 2"
                            v-model="editedItem.user_id"
                            :label="trans('fields.common.user')"
                            :error-messages="errors.user_id"
                            @input="clearError('user_id')"
                        ></user-autocomplete>
                        <v-switch
                            v-model="editedItem.status"
                            :label="trans('fields.common.status')"
                            :error-messages="errors.status"
                            @input="clearError('status')"
                            :true-value="1"
                            :false-value="0"
                            color="green"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="8" v-if="editedItem.type_id">
                        <v-row>
                            <v-col
                                v-for="(item, itemName) in typeFields"
                                :key="itemName"
                                cols="12" lg="6"
                            >
                                <offer-field :item="item" :item-name="itemName" :model="editedItem" :languageLocales="options.languageLocalesKeyed" ></offer-field>
                            </v-col>
                        </v-row>


                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="editedItem.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "OfferForm",
        mixins: [formValidate],
        components: {
            UserAutocomplete: () => import('@/components/admin/user/UserAutocomplete'),
            OfferField: () => import('@/components/commonComponents/OfferField')
        },
        props: {
            editedItem: {
                type: Object,
            },
            options: {
                type: Object,
            }
        },
        data(){
            return {
                logoFile: null,
                logoLoading: false,
            }
        },
        methods: {
            logoChange(file){
                if (file){
                    this.logoLoading = true;
                    let reader = new FileReader();
                    reader.onload = e => {
                        console.log(e);
                        this.editedItem.logoUrl = e.target.result;
                    };
                    reader.readAsDataURL(file);
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post( this.route('admin.offers.uploadLogo'),
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((response) => {
                        this.editedItem.logo_path = response.data.logo_path;
                    }).catch(() => {
                        console.log('FAILURE!!');
                    }).then( () => {
                        this.logoLoading = false;
                    });
                } else {
                    this.editedItem.logoUrl = null;
                }
            },
            save() {
                if (!this.editedItem.id) {
                    axios.post(this.route('admin.offers.store'), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.offers.update', this.editedItem.id), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
            setDefaultFields(offerTypeId){
                let defaultFields = {};
                let data = this.options.offerTypesFields[offerTypeId];
                _.defaultsDeep(defaultFields, data.dummy);
                _.mergeWith(defaultFields, this.editedItem.fields, (objValue, srcValue) => {
                    if (srcValue === null) return objValue;
                });
                this.editedItem.fields = defaultFields;
            },
        },
        created(){
            if(this.editedItem.type_id){
                this.setDefaultFields(this.editedItem.type_id);
            }
        },
        computed: {
            typeFields() {
                let typeFields = {};
                if (this.editedItem.type_id){
                    let data = this.options.offerTypesFields[this.editedItem.type_id];
                    _.defaultsDeep(typeFields, data.fields);
                    _.defaultsDeep(typeFields, data.dummy);
                    _.defaultsDeep(typeFields, this.editedItem.fields);
                }
                return typeFields;
            },

            formTitle() {
                return !this.editedItem.id
                    ? this.trans('pages.offer.newDialogTitle')
                    : this.trans('pages.offer.editDialogTitle');
            },
        }
    }
</script>
